import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { Gender } from 'src/app/constants/format';

interface GenderFieldProps {
  className?: string;
  name: string;
}

@withStyles(require('./GenderField.scss'))
export class GenderField extends React.PureComponent<GenderFieldProps> {
  render() {
    const { className, name } = this.props;
    const GenderFieldClass = classNames('GenderField', className);
    return (
      <div className={GenderFieldClass}>
        <label>
          <Field name={name} component="input" type="radio" value={Gender.MALE} />
          <FormattedMessage id="form.profile.label.gender.male" />
        </label>
        <label>
          <Field name={name} component="input" type="radio" value={Gender.FEMALE} />
          <FormattedMessage id="form.profile.label.gender.female" />
        </label>
      </div>
    );
  }
}
