import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import { FormattedMessage } from 'react-intl';
import { ButtonClassnames, ButtonProps } from 'src/app/components/generics/Button';

@withStyles(require('./Button.scss'))
export class SubmitButton extends React.PureComponent<React.HTMLProps<HTMLInputElement> & ButtonProps> {
  render() {
    const { color, format, shadow, customRef, value, ...restOfProps } = this.props;
    return (
      <FormattedMessage id={String(value) || 'placeholder.null'}>
        {/* tslint:disable-next-line:jsx-no-multiline-js */
        (formattedValue) => (
          <input
            {...restOfProps}
            className={ButtonClassnames(color || 'positive', format || 'large', shadow)}
            type="submit"
            ref={customRef}
            value={String(formattedValue)}
          />
        )}
      </FormattedMessage>
    );
  }
}
