import classNames from 'classnames';
import * as moment from 'moment';
import * as QueryString from 'query-string';
import * as React from 'react';
import { Query } from 'react-apollo';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { FormattedMessage } from 'react-intl';
import { Field, Form, InjectedFormProps, reduxForm, WrappedFieldProps } from 'redux-form';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';

import { Box, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { REFERRAL_CODE_NOT_FOUND_MESSAGE } from 'src/app/components/constants';
import { DateInput } from 'src/app/components/Forms/DateInput';
import { FieldError } from 'src/app/components/Forms/FieldError';
import { GenderField } from 'src/app/components/Forms/GenderField';
import { PinInput } from 'src/app/components/Forms/PinInput';
import { TextInput } from 'src/app/components/Forms/TextInput';
import { TextInputGroup } from 'src/app/components/Forms/TextInputGroup';
import { SubmitButton } from 'src/app/components/generics/SubmitButton';
import { TermsAndConditionsPopover } from 'src/app/components/generics/TermsAndConditionsPopover';
import { HttpIcon } from 'src/app/components/icons/HttpIcon';
import { JumpstartLogo } from 'src/app/components/logos';
import { ConfigProps, withConfig } from 'src/app/components/WithConfig/WithConfig';
import { LoginInput } from 'src/app/containers/Login/Login.queries';
import { LoginStep } from 'src/app/containers/Login/LoginStep';
import { email, phoneNo, required, sixDigit } from 'src/app/forms/validators';
import { toTrimmedPhoneNo } from 'src/app/utils/PhoneNoUtil';
import { FETCH_TNC, FetchTncQueryResult } from './Tnc.queries';

export interface LoginFormInjectedProps {
  injectedHandleSubmit: (formData: LoginInput) => void;
  currentLoginStep?: LoginStep;
  resendOtpSubmitFn: (event: any) => any;
  activePhoneNo?: string;
  otpCooldown: number;
  otpCount: number;
  forgotPinOnClick: (phoneNo: string) => any;
  errors: string[];
  setToken: (token: string) => void;
}

interface LoginFormState {
  termsAndConditionsIsExpanded: boolean;
}

type LoginFormProps = LoginFormInjectedProps & InjectedFormProps & ConfigProps & RouteComponentProps;

@withStyles(require('./LoginForm.scss'))
class LoginFormComponent extends React.PureComponent<LoginFormProps, LoginFormState> {
  constructor(props: LoginFormProps) {
    super(props);

    const queryString = QueryString.parse(props.history.location.search);
    this.props.initialize({
      referralCode: queryString && queryString.referral ? queryString.referral : undefined
    });
    this.state = {
      termsAndConditionsIsExpanded: false
    };
  }

  toggleTermsAndConditionsExpandState = () => {
    const { termsAndConditionsIsExpanded } = this.state;
    this.setState({
      termsAndConditionsIsExpanded: !termsAndConditionsIsExpanded
    });
  };

  handleTermsAndConditionsClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    this.toggleTermsAndConditionsExpandState();
  };

  handleTermsAndConditionsClose = () => {
    this.toggleTermsAndConditionsExpandState();
  };

  render() {
    return (
      <>
        {this.needToSendOtp() && this.renderReCaptcha()}
        {this.renderErrorMessage()}
        {this.renderForm()}
      </>
    );
  }

  renderForm = () => {
    const { currentLoginStep, handleSubmit, injectedHandleSubmit } = this.props;
    const { termsAndConditionsIsExpanded } = this.state;
    const phoneNoClass = classNames({
      LoginForm__page: true,
      'LoginForm__page--active': currentLoginStep === undefined
    });
    const loginPinClass = classNames({
      LoginForm__page: true,
      'LoginForm__page--active': currentLoginStep === LoginStep.LOGIN
    });
    const registerOtpClass = classNames({
      LoginForm__page: true,
      'LoginForm__page--active':
        currentLoginStep === LoginStep.REGISTER_OTP || currentLoginStep === LoginStep.REGISTER_FORGOT_OTP
    });
    return (
      <Form className="LoginForm" onSubmit={handleSubmit(injectedHandleSubmit)}>
        <figure className="LoginForm__logo">
          <JumpstartLogo size="login" />
        </figure>
        <div className={phoneNoClass}>
          <p className="LoginForm__header">
            <FormattedMessage id="form.login.label.phoneNo" />
          </p>
          <p className="LoginForm__subheader">
            <FormattedMessage id="form.login.label.phoneNo.tips" />
          </p>
          <Field
            name="phoneNo"
            component={this.phoneNoFieldComponent}
            pattern="[0-9]{7,12}"
            validate={[required, phoneNo]}
          />
          {this.renderReCaptchaPolicyLabel()}
        </div>
        <div className={loginPinClass}>
          <p className="LoginForm__header">
            <FormattedMessage id="form.login.label.pin" />
          </p>
          <p className="LoginForm__subheader">
            <FormattedMessage id="form.login.label.pin.tips" />
          </p>
          {currentLoginStep === LoginStep.LOGIN && this.renderLoginField()}
          {currentLoginStep === LoginStep.LOGIN && this.renderForgotPin()}
        </div>
        <div className={registerOtpClass}>
          <p className="LoginForm__header">
            <FormattedMessage id="form.login.label.otp" />
          </p>
          <p className="LoginForm__subheader">
            <FormattedMessage id="form.login.label.otp.tips" /> +62 {toTrimmedPhoneNo(this.props.activePhoneNo)}
          </p>
          {(currentLoginStep === LoginStep.REGISTER_OTP || currentLoginStep === LoginStep.REGISTER_FORGOT_OTP) &&
            this.renderOtpField()}
          <div className="LoginForm__resend-otp">{this.renderResendOtpLink()}</div>
        </div>
        {(currentLoginStep === LoginStep.REGISTER ||
          currentLoginStep === LoginStep.REGISTER_DETAIL ||
          currentLoginStep === LoginStep.REGISTER_FORGOT) &&
          this.renderRegisterField()}
        {currentLoginStep === LoginStep.REGISTER_DETAIL && this.renderRegisterDetailField()}
        <div className="LoginForm__footer-offset" />
        <div className="LoginForm__footer">
          <div>
            <SubmitButton shadow="standard" value="form.login.action.next" />
          </div>
          <p className="LoginForm__terms">
            <FormattedMessage id="form.login.register.termsAndConditions.intro" />
          </p>
          <a className="LoginForm__terms-link" href="#" onClick={this.handleTermsAndConditionsClick}>
            <FormattedMessage id="form.login.register.termsAndConditions" />
          </a>
        </div>
        <Query<FetchTncQueryResult> query={FETCH_TNC}>
          {// tslint:disable-next-line: jsx-no-multiline-js
          ({ data, loading }) => {
            return (
              <TermsAndConditionsPopover
                handleCloseFn={this.handleTermsAndConditionsClose}
                isExpanded={termsAndConditionsIsExpanded}
                text={loading ? '' : data && data.tnc.description}
              />
            );
          }}
        </Query>
      </Form>
    );
  };

  renderResendOtpLink = () => {
    const { resendOtpSubmitFn, errors } = this.props;
    if (this.props.otpCooldown === 0) {
      return (
        <>
          <p className="LoginForm__resend-otp-label">
            <FormattedMessage id="form.login.label.otp.resend" />
          </p>
          <a className="LoginForm__resend-otp-link" onClick={resendOtpSubmitFn}>
            <FormattedMessage id="form.login.action.otp.resend" />
          </a>
          {errors.length !== 0 && (
            <div className="LoginForm__error-container">
              <span id="ResentOtp__error" className="LoginForm__error-message">
                {errors}
              </span>
            </div>
          )}
          {this.renderReCaptchaPolicyLabel()}
        </>
      );
    } else {
      return (
        <>
          {errors.length === 0 ? (
            <p className="LoginForm__resend-otp-label">
              <FormattedMessage
                id={
                  this.props.otpCount === 1
                    ? 'form.login.label.otp.send.successful'
                    : 'form.login.label.otp.resend.successful'
                }
              />
            </p>
          ) : (
            <div className="LoginForm__error-container">
              <span id="ResentOtp__error" className="LoginForm__error-message">
                {errors}
              </span>
            </div>
          )}
          <p className="LoginForm__resend-otp-label">
            <FormattedMessage id="form.login.label.otp.resend.cooldown.timer" />
            &nbsp;{this.props.otpCooldown}&nbsp;
            <FormattedMessage id="form.login.label.otp.resend.cooldown.timer.unit" />
          </p>
        </>
      );
    }
  };

  phoneNoFieldComponent = (field: WrappedFieldProps) => {
    return (
      <div>
        <TextInputGroup>
          <TextInput value={'+62'} disabled={true} style={{ flexGrow: 0, width: 68 }} />
          <TextInput {...field.input} autoFocus={true} inputMode="numeric" />
        </TextInputGroup>
        <p className="LoginForm__error">
          <FieldError meta={field.meta} />
        </p>
      </div>
    );
  };

  formDetailFieldComponent = (field: WrappedFieldProps) => {
    return (
      <div className="LoginForm__detail">
        <TextInput {...field.input} />
        <p className="LoginForm__detail-error">
          <FieldError meta={field.meta} />
        </p>
      </div>
    );
  };

  wrappedDateField = (field: WrappedFieldProps) => {
    let formatter = (value?: string) => (value ? moment(value).toDate() : undefined);
    let dateParser = (value?: string) => (value ? moment(value, 'DD/MM/YYYY').toDate() : undefined);
    let maxDate = moment().toDate();
    return (
      <div className="LoginForm__date">
        <div className="Field__input">
          <DateInput {...field.input} format={formatter} parse={dateParser} maxDate={maxDate} />
        </div>
        <div className="Field__error">
          <FieldError meta={field.meta} />
        </div>
      </div>
    );
  };

  pinFieldComponent = (field: WrappedFieldProps) => {
    return (
      <>
        <PinInput {...field.input} autoFocus={true} onFocus={this.handlePinFocus(field.input.name)} />
        <p className="LoginForm__error">
          <FieldError meta={field.meta} />
        </p>
      </>
    );
  };

  otpFieldComponent = (field: WrappedFieldProps) => {
    return (
      <>
        <PinInput {...field.input} showPin={true} autoFocus={true} onFocus={this.handlePinFocus(field.input.name)} />
        <p className="LoginForm__error">
          <FieldError meta={field.meta} />
        </p>
      </>
    );
  };

  handlePinChange = (event: any, newValue: string) => {
    const maxPinLength = 6;
    if (newValue.length === maxPinLength) {
      event.target.blur();
    }
  };

  handlePinFocus = (fieldName: string) => (event: any) => {
    this.props.change(fieldName, '');
  };

  renderLoginField = () => <Field name="password" component={this.pinFieldComponent} onChange={this.handlePinChange} />;

  renderForgotPin = () => {
    const { activePhoneNo, forgotPinOnClick, errors } = this.props;
    return (
      <>
        <div className="LoginForm__forgot-otp">
          <a
            // tslint:disable-next-line: jsx-no-lambda
            onClick={() => forgotPinOnClick(activePhoneNo!)}
          >
            <FormattedMessage id="form.login.action.otp.forgot" />
          </a>
          {errors.length !== 0 && (
            <div className="LoginForm__error-container">
              <span id="ForgotPin__error" className="LoginForm__error-message">
                {errors}
              </span>
            </div>
          )}
        </div>
        {this.renderReCaptchaPolicyLabel()}
      </>
    );
  };

  renderOtpField = () => <Field name="otp" component={this.otpFieldComponent} onChange={this.handlePinChange} />;

  renderRegisterField = () => {
    const { currentLoginStep } = this.props;
    const registerPasswordClass = classNames({
      LoginForm__page: true,
      'LoginForm__page--active':
        currentLoginStep === LoginStep.REGISTER || currentLoginStep === LoginStep.REGISTER_FORGOT
    });
    return (
      <div className={registerPasswordClass}>
        <p className="LoginForm__header">
          <FormattedMessage id="form.login.label.pin.new" />
        </p>
        <p className="LoginForm__subheader">
          <FormattedMessage id="form.login.label.pin.new.tips" />
        </p>
        <Field
          name="password"
          component={this.pinFieldComponent}
          validate={[required, sixDigit]}
          onChange={this.handlePinChange}
        />
      </div>
    );
  };

  renderRegisterDetailField = () => {
    const { currentLoginStep } = this.props;
    const registerDetailClass = classNames({
      LoginForm__page: true,
      'LoginForm__page--active': currentLoginStep === LoginStep.REGISTER_DETAIL
    });
    return (
      <div className={registerDetailClass}>
        <label htmlFor="name" className="LoginForm__detail-label">
          <FormattedMessage id="form.login.label.name" />
        </label>
        <Field name="name" component={this.formDetailFieldComponent} validate={required} />
        <label htmlFor="email" className="LoginForm__detail-label">
          <FormattedMessage id="form.login.label.email" />
        </label>
        <Field name="email" component={this.formDetailFieldComponent} validate={[email, required]} />
        <label htmlFor="birthdate" className="LoginForm__detail-label">
          <FormattedMessage id="form.login.label.dateOfBirth" />
        </label>
        <Field name="birthdate" component={this.wrappedDateField} validate={required} />
        <label htmlFor="gender" className="LoginForm__detail-label">
          <FormattedMessage id="form.login.label.gender" />
        </label>
        <div className="LoginForm__gender">
          <GenderField name="gender" />
        </div>
        <label htmlFor="referralCode" className="LoginForm__detail-label">
          <FormattedMessage id="form.login.label.referralCode" />
        </label>
        <Field name="referralCode" component={this.formDetailFieldComponent} />
      </div>
    );
  };

  renderErrorMessage = () => {
    if (this.props.errors.length === 0 || this.props.errors[0] !== REFERRAL_CODE_NOT_FOUND_MESSAGE) {
      return null;
    }

    return (
      this.props.currentLoginStep &&
      this.props.currentLoginStep === LoginStep.REGISTER_DETAIL && (
        <div className="LoginForm__error-header">
          <div className="LoginForm__error-icon" />
          <div>
            <div className="LoginForm__error-message-header">
              <FormattedMessage id="form.login.error.invalidReferralCode.header" />
            </div>
            <div className="LoginForm__error-message-content">
              <FormattedMessage id="form.login.error.invalidReferralCode.content" />
            </div>
          </div>
        </div>
      )
    );
  };

  private needToSendOtp = () => {
    const { currentLoginStep } = this.props;
    return (
      currentLoginStep === undefined ||
      currentLoginStep === LoginStep.LOGIN ||
      currentLoginStep === LoginStep.REGISTER_OTP ||
      currentLoginStep === LoginStep.REGISTER_FORGOT_OTP
    );
  };

  private renderReCaptcha = () => (
    <GoogleReCaptchaProvider reCaptchaKey={this.props.config.app.reCaptcha.siteKey}>
      <GoogleReCaptcha onVerify={this.props.setToken} />
    </GoogleReCaptchaProvider>
  );

  private renderReCaptchaPolicyLabel = () => (
    <Typography variant="caption" color="textSecondary" component="p">
      <Box display="flex" margin="0px 10px">
        <Box width="70px" textAlign="center">
          <HttpIcon fontSize="large" style={{ height: '85%' }} />
        </Box>
        <Box>
          <Typography variant="caption" color="textSecondary" component="p">
            {
              <p>
                This site is protected by reCAPTCHA and the Google{' '}
                <a href="https://policies.google.com/privacy" target="_blank">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="https://policies.google.com/terms" target="_blank">
                  Terms of Service
                </a>{' '}
                apply.
              </p>
            }
          </Typography>
        </Box>
      </Box>
    </Typography>
  );
}

const LoginFormComponentWithConfig = (props: LoginFormProps) =>
  withConfig({ Component: LoginFormComponent, componentProps: props });

export const LoginForm = reduxForm<{}, any>({
  form: 'LoginForm'
})(withRouter(LoginFormComponentWithConfig));
