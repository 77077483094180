import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';

@withStyles(require('./TextInputGroup.scss'))
export class TextInputGroup extends React.PureComponent {
  render() {
    return <div className="TextInputGroup">{this.props.children}</div>;
  }
}
