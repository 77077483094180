export const required = (value: any) => {
  if (value === undefined) {
    return 'validator.required';
  }

  if (value === '') {
    return 'validator.required';
  }

  return undefined;
};
