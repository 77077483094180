import * as React from 'react';

import ApolloClient from 'apollo-client';
import { ApolloConsumer } from 'react-apollo';
import { Redirect, RouteComponentProps } from 'react-router';
import { SubmissionError } from 'redux-form';
import { REFERRAL_CODE_NOT_FOUND_MESSAGE } from 'src/app/components/constants';
import { BackCloseButton } from 'src/app/components/generics';
import { NavigationBar } from 'src/app/components/NavigationBar';
import { AuthProps, withAuth } from 'src/app/components/WithAuth';
import { HOME } from 'src/app/constants/route';
import {
  CHANGE_PASSWORD_MUTATION,
  ChangePasswordFormData,
  ChangePasswordQueryInput,
  CHECK_PHONE_NO_AND_GENERATE_OTP_IF_NOT_EXIST_QUERY,
  CheckPhoneNoInput,
  CREATE_CUSTOMER_MUTATION,
  CreateCustomerInput,
  CustomerRegistrationForm,
  DO_LOGIN_MUTATION,
  FORGET_PASSWORD_MUTATION,
  ForgotPasswordInput,
  GENERATE_OTP_FOR_FORGOT_PASSWORD_QUERY,
  LoginFormData,
  LoginInput,
  LoginQueryResult,
  OtpFormData,
  REGENERATE_OTP_QUERY,
  RegenerateOtpQueryInput,
  VALIDATE_OTP_QUERY
} from 'src/app/containers/Login/Login.queries';
import { LoginFactory } from 'src/app/containers/Login/LoginFactory';
import { LoginForm } from 'src/app/containers/Login/LoginForm';
import { LoginStep } from 'src/app/containers/Login/LoginStep';

interface LoginState {
  loginStep?: LoginStep;
  activePhoneNo?: string;
  otpCooldown: number;
  otpCount: number;
  errors: string[];
}

type LoginProps = AuthProps & RouteComponentProps;

const OTP_LENGTH = 6;

class $Login extends React.PureComponent<LoginProps, LoginState> {
  private readonly USER_IS_EXIST = -1;
  private otpTimerInterval: any;
  private token: string;

  constructor(props: LoginProps) {
    super(props);

    this.state = {
      errors: [],
      loginStep: undefined,
      otpCooldown: 0,
      otpCount: 0
    };
  }

  componentWillUnmount(): void {
    if (this.otpTimerInterval) {
      clearInterval(this.otpTimerInterval);
    }
  }

  render() {
    const { me } = this.props;
    if (me) {
      return <Redirect to={HOME.path} />;
    }
    return (
      <div>
        <NavigationBar
          leftSection={
            <BackCloseButton backState={this.state.loginStep !== undefined} onClick={this.handleBackButton} />
          }
        />
        <ApolloConsumer>
          {/* tslint:disable-next-line:jsx-no-multiline-js */
          client => (
            <LoginForm
              currentLoginStep={this.state.loginStep}
              injectedHandleSubmit={this.getSubmitFnBasedOnLoginStep(client)}
              activePhoneNo={this.state.activePhoneNo}
              resendOtpSubmitFn={this.handleResendOtpSubmit(client)}
              otpCooldown={this.state.otpCooldown}
              otpCount={this.state.otpCount}
              forgotPinOnClick={this.handleForgotPinClick(client)}
              errors={this.state.errors}
              setToken={this.setToken}
            />
          )}
        </ApolloConsumer>
      </div>
    );
  }

  private handleBackButton = (event: any) => {
    switch (this.state.loginStep) {
      case LoginStep.LOGIN:
        return this.setState({ loginStep: undefined });
      case LoginStep.REGISTER_FORGOT_OTP:
        return this.setState({ loginStep: LoginStep.LOGIN });
      case LoginStep.REGISTER_FORGOT:
        return this.setState({ loginStep: undefined });
      case LoginStep.REGISTER_OTP:
        return this.setState({ loginStep: undefined });
      case LoginStep.REGISTER:
        return this.setState({ loginStep: undefined });
      case LoginStep.REGISTER_DETAIL:
        return this.setState({ loginStep: LoginStep.REGISTER });
      default:
        return this.props.history.push(HOME.path);
    }
  };

  private setToken = (token: string) => {
    this.token = token;
  };

  private getSubmitFnBasedOnLoginStep = (client: ApolloClient<{}>): any => {
    switch (this.state.loginStep) {
      case LoginStep.LOGIN:
        return this.handleLoginSubmit(client);
      case LoginStep.REGISTER_OTP:
        return this.handleOtpCheckSubmit(client);
      case LoginStep.REGISTER_FORGOT_OTP:
        return this.handleOtpForgotPasswordCheckSubmit(client);
      case LoginStep.REGISTER_FORGOT:
        return this.handleRegisterForgotPasswordSubmit(client);
      case LoginStep.REGISTER:
        return this.handleRegisterPasswordSubmit(client);
      case LoginStep.REGISTER_DETAIL:
        return this.handleRegisterFormSubmit(client);
      default:
        return this.handlePhoneNoSubmit(client);
    }
  };

  private handleResendOtpSubmit = (client: ApolloClient<{}>) => (event: any) => {
    if (this.state.activePhoneNo === undefined || this.state.otpCooldown !== 0) {
      throw new SubmissionError({
        _error: 'Resend OTP Error',
        phoneNo: 'form.login.error.otp.resend.invalid'
      });
    }
    return client
      .query<RegenerateOtpQueryInput>({
        fetchPolicy: 'no-cache',
        query: REGENERATE_OTP_QUERY,
        variables: LoginFactory.toResendOtpInput(this.state.activePhoneNo, this.token)
      })
      .then((resolve: any) => {
        window.dataLayer.push({
          event: 'trackEvent',
          eventDetails: { action: 'Resend OTP Registration', category: 'Customer' }
        });
        const { data, errors } = resolve;
        if (data) {
          const succesfullyGeneratedOtpCooldown = resolve.data.regenerateOtp;
          if (succesfullyGeneratedOtpCooldown) {
            this.setState(
              {
                errors: [],
                otpCooldown: succesfullyGeneratedOtpCooldown,
                otpCount: this.state.otpCount + 1
              },
              () => {
                if (this.otpTimerInterval) {
                  clearInterval(this.otpTimerInterval);
                }
                this.otpTimerInterval = setInterval(this.handleOtpTick, 1000);
              }
            );
          }
        } else {
          if (errors) {
            this.setState({ errors: errors[0].message });
          }
        }
      });
  };

  private handleOtpTick = () => {
    if (this.state.otpCooldown !== 0) {
      this.setState({ otpCooldown: this.state.otpCooldown - 1 });
    } else {
      clearInterval(this.otpTimerInterval);
      this.setState({ errors: [] });
    }
  };
  private handlePhoneNoSubmit = (client: ApolloClient<{}>) => (formData: CheckPhoneNoInput) => {
    window.dataLayer.push({
      event: 'trackEvent',
      eventDetails: { action: 'Submit Phone Number Authentication', category: 'Customer' }
    });
    return client
      .query({
        fetchPolicy: 'no-cache',
        query: CHECK_PHONE_NO_AND_GENERATE_OTP_IF_NOT_EXIST_QUERY,
        variables: LoginFactory.toCheckPhoneNoInput(formData.phoneNo, this.token)
      })
      .then((resolve: any) => {
        const { data, errors } = resolve;
        if (data) {
          const userExist: boolean = resolve.data.checkPhoneNoAndGenerateOtpIfNotExist === this.USER_IS_EXIST;
          if (userExist) {
            return this.setState({
              activePhoneNo: formData.phoneNo,
              errors: [],
              loginStep: LoginStep.LOGIN
            });
          }
          this.setState(
            {
              activePhoneNo: formData.phoneNo,
              errors: [],
              loginStep: LoginStep.REGISTER_OTP,
              otpCooldown: resolve.data.checkPhoneNoAndGenerateOtpIfNotExist,
              otpCount: this.state.otpCount + 1
            },
            () => {
              if (this.otpTimerInterval) {
                clearInterval(this.otpTimerInterval);
              }
              this.otpTimerInterval = setInterval(this.handleOtpTick, 1000);
            }
          );
        } else {
          if (errors[0].message === 'You are not allowed to access this site') {
            throw new SubmissionError({
              _error: errors[0].message,
              phoneNo: 'validator.isRobot'
            });
          }
          if (errors[0].message === 'Too many attempts. Please try again later') {
            throw new SubmissionError({
              _error: errors[0].message,
              phoneNo: 'validator.tooManyAttempts'
            });
          }
          throw new SubmissionError({
            _error: errors[0].message,
            phoneNo: 'validator.limitOtp'
          });
        }
      });
  };

  private handleForgotPinClick = (client: ApolloClient<{}>) => (phoneNumber: string) => {
    client
      .mutate<LoginQueryResult, ForgotPasswordInput>({
        mutation: FORGET_PASSWORD_MUTATION,
        variables: LoginFactory.toForgotPasswordInput(this.state.activePhoneNo!)
      })
      .then((resolve: any) => {
        const { data, errors } = resolve;
        if (data) {
          return client
            .query({
              fetchPolicy: 'no-cache',
              query: GENERATE_OTP_FOR_FORGOT_PASSWORD_QUERY,
              variables: LoginFactory.toForgotPINInput(phoneNumber, this.token)
            })
            .then((resolve2: any) => {
              const { data: data2, errors: errors2 } = resolve2;
              if (data2) {
                window.dataLayer.push({
                  event: 'trackEvent',
                  eventDetails: { action: 'Forgot PIN', category: 'Customer' }
                });
                this.setState(
                  {
                    activePhoneNo: phoneNumber,
                    loginStep: LoginStep.REGISTER_FORGOT_OTP,
                    otpCooldown: resolve2.data.generateOtpForForgotPassword,
                    otpCount: this.state.otpCount + 1
                  },
                  () => {
                    if (this.otpTimerInterval) {
                      clearInterval(this.otpTimerInterval);
                    }
                    this.otpTimerInterval = setInterval(this.handleOtpTick, 1000);
                  }
                );
              } else {
                if (errors2) {
                  this.setState({ errors: errors2[0].message });
                }
              }
            });
        } else {
          // tslint:disable-next-line: no-console
          console.error(errors);
          return;
        }
      });
  };

  private handleOtpForgotPasswordCheckSubmit = (client: ApolloClient<{}>) => (formData: OtpFormData) => {
    if (!formData.otp) {
      throw new SubmissionError({
        _error: 'OTP required',
        otp: 'validator.required'
      });
    } else if (formData.otp.length < OTP_LENGTH) {
      throw new SubmissionError({
        _error: `OTP must be ${OTP_LENGTH}-digit`,
        otp: 'validator.sixDigitOTP'
      });
    }
    return client
      .query({
        fetchPolicy: 'network-only',
        query: VALIDATE_OTP_QUERY,
        variables: LoginFactory.toOtpCheckInput(formData)
      })
      .then((resolve: any) => {
        const { data } = resolve;
        if (data) {
          const otpIsValid: boolean = resolve.data.validateOtp;
          if (otpIsValid) {
            return this.setState({
              loginStep: LoginStep.REGISTER_FORGOT
            });
          } else {
            throw new SubmissionError({
              _error: 'OTP verification failed: Incorrect OTP.',
              otp: 'form.login.error.otp.incorrect'
            });
          }
        } else {
          throw new SubmissionError({
            _error: 'OTP verification failed: Can’t connect to server.',
            otp: 'form.login.error.otp.api'
          });
        }
      });
  };

  private handleOtpCheckSubmit = (client: ApolloClient<{}>) => (formData: OtpFormData) => {
    if (!formData.otp) {
      throw new SubmissionError({
        _error: 'OTP required',
        otp: 'validator.required'
      });
    } else if (formData.otp.length < OTP_LENGTH) {
      throw new SubmissionError({
        _error: `OTP must be ${OTP_LENGTH}-digit`,
        otp: 'validator.sixDigitOTP'
      });
    }
    return client
      .query({
        fetchPolicy: 'network-only',
        query: VALIDATE_OTP_QUERY,
        variables: LoginFactory.toOtpCheckInput(formData)
      })
      .then((resolve: any) => {
        const { data } = resolve;
        if (data) {
          const otpIsValid: boolean = resolve.data.validateOtp;

          if (otpIsValid) {
            window.dataLayer.push({
              event: 'trackEvent',
              eventDetails: { action: 'Submit OTP Registration', category: 'Customer' }
            });
            return this.setState({
              loginStep: LoginStep.REGISTER
            });
          } else {
            throw new SubmissionError({
              _error: 'OTP verification failed: Incorrect OTP.',
              otp: 'form.login.error.otp.incorrect'
            });
          }
        } else {
          throw new SubmissionError({
            _error: 'OTP verification failed: Can’t connect to server.',
            otp: 'form.login.error.otp.api'
          });
        }
      });
  };

  private handleLoginSubmit = (client: ApolloClient<{}>) => (formData: LoginFormData) => {
    if (!formData.password) {
      throw new SubmissionError({
        _error: 'PIN required.',
        password: 'validator.required'
      });
    } else if (formData.password.length < OTP_LENGTH) {
      throw new SubmissionError({
        _error: `PIN must be ${OTP_LENGTH}-digit.`,
        password: 'validator.sixDigit'
      });
    }
    return client
      .mutate<LoginQueryResult, LoginInput>({
        mutation: DO_LOGIN_MUTATION,
        variables: LoginFactory.toLoginInput(formData)
      })
      .then((resolve: any) => {
        const { data, errors } = resolve;
        if (data) {
          window.dataLayer.push({
            event: 'trackEvent',
            eventDetails: { action: 'Login', category: 'Customer' },
            userId: data.doLogin.id
          });
          this.props.history.push(HOME.path);
        } else {
          if (errors) {
            throw new SubmissionError({
              _error: 'Login failed: Incorrect password.',
              password: 'form.login.error.pin.incorrect'
            });
          }
        }
      });
  };

  private handleRegisterForgotPasswordSubmit = (client: ApolloClient<{}>) => (formData: ChangePasswordFormData) => {
    client
      .mutate<LoginQueryResult, ChangePasswordQueryInput>({
        mutation: CHANGE_PASSWORD_MUTATION,
        variables: LoginFactory.toForgotChangePasswordInput(formData)
      })
      .then((resolve: any) => {
        const { data, errors } = resolve;
        if (data) {
          this.props.history.push(HOME.path);
        } else {
          // tslint:disable-next-line:no-console
          console.error(errors.shift().message);
        }
      });
  };

  private handleRegisterPasswordSubmit = (client: ApolloClient<{}>) => () => {
    this.setState({
      loginStep: LoginStep.REGISTER_DETAIL
    });
  };

  private handleRegisterFormSubmit = (client: ApolloClient<{}>) => (formData: CustomerRegistrationForm) => {
    client
      .mutate<LoginQueryResult, CreateCustomerInput>({
        mutation: CREATE_CUSTOMER_MUTATION,
        variables: LoginFactory.toCreateCustomerInput(formData)
      })
      .then((resolve: any) => {
        const { data, errors } = resolve;
        if (errors) {
          this.setState({ ...this.state, errors: errors.map((error: any) => error.message) });
          if (errors.length !== 0 && errors[0].message && errors[0].message === REFERRAL_CODE_NOT_FOUND_MESSAGE) {
            this.scrollToTop();
          }
        }
        if (data && data.createCustomer) {
          window.dataLayer.push({
            event: 'trackEvent',
            eventDetails: { action: 'Register', category: 'Customer' },
            userId: data.createCustomer.id
          });
          this.props.history.push(HOME.path);
        } else {
          // tslint:disable-next-line:no-console
          console.error(errors.shift().message);
        }
      });
  };

  private scrollToTop = () => {
    const element = document.getElementsByClassName('NavigationBar__offset');
    if (element && element[0]) {
      element[0].scrollIntoView({ behavior: 'smooth' });
    }
  };
}

export const Login = (props: LoginProps) =>
  withAuth({
    Component: $Login,
    authenticationRequired: false,
    componentProps: props
  });
