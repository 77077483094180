import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

interface NavigationBarProps {
  leftSection?: JSX.Element;
  title?: string;
  centerSection?: JSX.Element;
  rightSection?: JSX.Element;
  absolute?: boolean;
  hideBackground?: boolean;
  className?: string;
}

@withStyles(require('./NavigationBar.scss'))
export class NavigationBar extends React.PureComponent<NavigationBarProps> {
  render() {
    const { leftSection, rightSection, absolute, hideBackground, className } = this.props;
    const navigationBarClass = classNames({
      NavigationBar: true,
      'NavigationBar--absolute': absolute,
      'NavigationBar--no-background': hideBackground,
      [`${className}`]: className !== undefined
    });
    return (
      <>
        <nav className={navigationBarClass}>
          <div className="NavigationBar__section NavigationBar__section--left">{leftSection || null}</div>
          <div className="NavigationBar__section NavigationBar__section--title">{this.renderCenterSection()}</div>
          <div className="NavigationBar__section NavigationBar__section--right">{rightSection || null}</div>
        </nav>
        <div className="NavigationBar__offset" />
      </>
    );
  }

  renderCenterSection = () => {
    const { centerSection, title } = this.props;
    if (centerSection) {
      return centerSection;
    }

    if (title) {
      return <FormattedMessage id={title} tagName="h1" />;
    }

    return null;
  };
}
