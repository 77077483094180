import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

export interface TextInputProps {
  customRef?: any;
}

@withStyles(require('./TextInput.scss'))
export class TextInput extends React.PureComponent<React.HTMLProps<HTMLInputElement> & TextInputProps> {
  render() {
    const { customRef, placeholder, className, ...restOfProps } = this.props;
    const textInputClass = classNames({
      TextInput,
      [`${className}`]: className !== undefined
    });
    return (
      <FormattedMessage id={placeholder || 'placeholder.null'}>
        {label => (
          <input
            {...restOfProps}
            placeholder={label as string}
            className={textInputClass}
            type="text"
            ref={customRef}
          />
        )}
      </FormattedMessage>
    );
  }
}
