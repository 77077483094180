import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import classNames from 'classnames';
import { CustomizationContext } from 'src/app/Customization';

interface JumpstartLogoProps {
  size?: 'home' | 'login' | 'small' | 'aboutUs';
}

@withStyles(require('./Logos.scss'))
export class JumpstartLogo extends React.PureComponent<JumpstartLogoProps> {
  render() {
    const { size } = this.props;
    const logoClass = classNames({
      JumpstartLogo,
      'JumpstartLogo--about-us': size === 'aboutUs',
      'JumpstartLogo--home': size === 'home',
      'JumpstartLogo--login': size === 'login',
      'JumpstartLogo--small': size === 'small'
    });

    return (
      <CustomizationContext.Consumer>
        {({ assets }) => <img className={logoClass} src={assets.logoUrl} alt="Jumpstart Logo" />}
      </CustomizationContext.Consumer>
    );
  }
}
