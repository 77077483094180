import * as moment from 'moment';
import {
  ChangePasswordFormData,
  ChangePasswordQueryInput,
  CheckPhoneNoInput,
  CreateCustomerInput,
  CustomerRegistrationForm,
  ForgotPasswordInput,
  GenerateOTPForForgotPasswordInput,
  LoginFormData,
  LoginInput,
  OtpCheckInput,
  OtpFormData,
  ResendOtpInput,
} from 'src/app/containers/Login/Login.queries';
import { toTrimmedPhoneNo } from 'src/app/utils/PhoneNoUtil';

export abstract class LoginFactory {
  public static toCheckPhoneNoInput(phoneNo: string, token: string): CheckPhoneNoInput {
    return {
      phoneNo: this.indonesianCountryCodePrefix + toTrimmedPhoneNo(phoneNo),
      token,
    };
  }

  public static toResendOtpInput(phoneNo: string, token: string): ResendOtpInput {
    return {
      phoneNo: this.indonesianCountryCodePrefix + toTrimmedPhoneNo(phoneNo),
      token,
    };
  }

  public static toLoginInput(formData: LoginFormData): LoginInput {
    return {
      loginInput: {
        password: formData.password,
        phoneNo: this.indonesianCountryCodePrefix + toTrimmedPhoneNo(formData.phoneNo),
      },
    };
  }

  public static toOtpCheckInput(formData: OtpFormData): OtpCheckInput {
    return {
      otpInput: {
        password: formData.otp,
        phoneNo: this.indonesianCountryCodePrefix + toTrimmedPhoneNo(formData.phoneNo),
      },
    };
  }

  public static toCreateCustomerInput(formData: CustomerRegistrationForm): CreateCustomerInput {
    return {
      input: {
        birthdate: moment(formData.birthdate).format('YYYY-MM-DD'),
        email: formData.email,
        gender: formData.gender,
        name: formData.name,
        password: formData.password,
        phoneNo: this.indonesianCountryCodePrefix + toTrimmedPhoneNo(formData.phoneNo),
        referredBy: formData.referralCode,
        status: 1,
      },
    };
  }

  public static toForgotPINInput(phoneNo: string, token: string): GenerateOTPForForgotPasswordInput {
    return {
      phoneNo: this.indonesianCountryCodePrefix + toTrimmedPhoneNo(phoneNo),
      token,
    };
  }

  public static toForgotPasswordInput(phoneNo: string): ForgotPasswordInput {
    return {
      input: {
        password: '',
        phoneNo: this.indonesianCountryCodePrefix + toTrimmedPhoneNo(phoneNo),
      },
    };
  }

  public static toForgotChangePasswordInput(formData: ChangePasswordFormData): ChangePasswordQueryInput {
    return {
      input: {
        password: formData.password,
        phoneNo: this.indonesianCountryCodePrefix + toTrimmedPhoneNo(formData.phoneNo),
      },
    };
  }
  private static indonesianCountryCodePrefix = '+62';
}
