export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_INPUT_FORMAT = 'YYYY-MM-DD';
export const COMMA_DATE_TIME_FORMAT = 'DD MMMM YYYY, HH:mm';
export const SEMICOLON_DATE_TIME_FORMAT = 'DD MMMM YYYY; HH:mm';
export const SPACE_DATE_FORMAT = 'DD MMM YY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME = 'ddd, DD MMM YYYY HH:mm:ss';
export const EXPIRED_DATE_TIME = 'ddd, DD MMM YYYY, HH:mm';
export const NO_DAY_DATE_TIME = 'DD MMM YYYY, HH:mm';
export enum MapType {
  STREET_NUMBER = 'street_number',
  ROUTE = 'route',
  POLITICAL = 'political',
  ADMINISTRATIVE_AREA_LEVEL_4 = 'administrative_area_level_4',
  ADMINISTRATIVE_AREA_LEVEL_3 = 'administrative_area_level_3',
  ADMINISTRATIVE_AREA_LEVEL_2 = 'administrative_area_level_2',
  ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1',
  COUNTRY = 'country',
  POSTAL_CODE = 'postal_code',
  LOCALITY = 'locality',
}
export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}
