import * as React from 'react';
import { Field, Validator } from 'redux-form';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import classNames from 'classnames';
import { DateTimePicker } from 'react-widgets';
import { DATE_FORMAT } from 'src/app/constants/format';

interface DateInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  validate?: Validator | Validator[];
  placeholder?: string;
  hideValidMarker?: boolean;
  format?: (value: any) => any;
  parse?: (value: any) => any;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
}

@withStyles(require('./DateInput.scss'))
export class DateInput extends React.PureComponent<DateInputProps> {
  render() {
    return (
      <Field
        placeholder={this.props.placeholder}
        component={dateInputField}
        name={this.props.name}
        validate={this.props.validate}
        className={classNames({ DateInput })}
        {...this.props}
      />
    );
  }
}

const dateInputField = ({ className, input, maxDate, minDate, disabled, meta }: any) => (
  <div className={classNames(className, meta && meta.touched && meta.error ? 'DateInput-error' : '')}>
    <DateTimePicker
      format={DATE_FORMAT}
      placeholder={DATE_FORMAT}
      time={false}
      {...input}
      value={input.value}
      max={maxDate}
      min={minDate}
      disabled={disabled}
      inputProps={{ readonly: 'readonly' }}
    />
  </div>
);
