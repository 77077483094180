import gql from 'graphql-tag';

export const FETCH_TNC = gql`
  query FetchTnc {
    tnc {
      description
    }
  }
`;

export interface FetchTncQueryResult {
  tnc: {
    description?: string;
  };
}
