import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import classNames from 'classnames';
import { WrappedFieldInputProps } from 'redux-form';

interface PinInputSpecificProps {
  showPin?: boolean;
}

type PinInputProps = PinInputSpecificProps & React.HTMLProps<HTMLInputElement> & WrappedFieldInputProps;

@withStyles(require('./PinInput.scss'))
export class PinInput extends React.PureComponent<PinInputProps> {
  render() {
    const { showPin, ...restOfProps } = this.props;
    const pinInputClass = classNames({
      PinInput,
      'PinInput--show-pin': showPin
    });
    return (
      <div className="PinInput__container">
        <div className="PinInput__background">
          <div className="PinInput__field"/>
          <div className="PinInput__field"/>
          <div className="PinInput__field"/>
          <div className="PinInput__field"/>
          <div className="PinInput__field"/>
          <div className="PinInput__field"/>
        </div>
        <input
          {...restOfProps}
          className={pinInputClass}
          type={showPin ? 'text' : 'password'}
          maxLength={6}
          pattern="[0-9]*"
          inputMode="numeric"
        />
      </div>
    );
  }
}
