import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import { FormattedMessage } from 'react-intl';
import { WrappedFieldMetaProps } from 'redux-form';

interface FieldErrorProps {
  meta: WrappedFieldMetaProps;
}

@withStyles(require('./FieldError.scss'))
export class FieldError extends React.PureComponent<FieldErrorProps> {
  render() {
    const { meta } = this.props;
    if (meta.error === undefined || !meta.touched) return null;

    return (
      <span className="FieldError">
        <FormattedMessage id={meta.error}/>
      </span>
    );
  }
}
