import React from 'react';
import { Config, ConfigContextConsumer } from 'src/app/models/config';

interface WithConfigProps {
  Component: React.ComponentType<any>;
  componentProps?: any;
}

export const withConfig = (props: WithConfigProps) => {
  const { Component } = props;
  return (
    <ConfigContextConsumer>
      {(config) => <Component {...props.componentProps} config={config} />}
    </ConfigContextConsumer>
  );
};

export interface ConfigProps {
  config: Config;
}
