import classNames from 'classnames';
import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import { JumpstartLogo } from 'src/app/components/logos';
import { NavigationBar } from 'src/app/components/NavigationBar';
import { BackCloseButton } from './BackCloseButton';

interface TermsAndConditionsPopoverProps {
  isExpanded?: boolean;
  text?: string;
  handleCloseFn: () => any;
}

@withStyles(require('./TermsAndConditionsPopover.scss'))
export class TermsAndConditionsPopover extends React.PureComponent<TermsAndConditionsPopoverProps> {
  render() {
    const { isExpanded, handleCloseFn, text } = this.props;
    const termsAndConditionsPopoverContainerClass = classNames({
      'TermsAndConditionsPopover__container': true,
      'TermsAndConditionsPopover__container--expanded': isExpanded
    });
    return (
      <div className={termsAndConditionsPopoverContainerClass}>
        <div className="TermsAndConditionsPopover">
          <NavigationBar
            leftSection={<BackCloseButton backState={false} onClick={handleCloseFn} />}
            title="page.termsAndConditions"
            absolute={true}
          />
          <div className="TermsAndConditionsPopover__content">
            <figure className="LoginForm__logo">
              <JumpstartLogo size="login" />
            </figure>
            {text}
          </div>
        </div>
      </div>
    );
  }
}
